import React, {useEffect, useState} from "react";
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import DopeContentPage from "../ui/DopeContentPage";
import DopeSelectDrawer from "../ui/DopeSelectDrawer";
import DopeDrawer from "../ui/DopeDrawer";
import DopeRadio from '../ui/DopeRadio';
import DesignOverviewDrawer from "./DesignOverviewDrawer";

import DopeButton from "../ui/DopeButton";
import { Icon } from "@rsuite/icons";
import { FaPlus } from "react-icons/fa";
import DesignGridPage from "./DesignGridPage";
import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import DopeIconCard from '../ui/DopeIconCard';
import { Divider } from "rsuite";
import DesignEditDrawer from "./DesignEditDrawer";
import { useDesign } from "./designSlice";

const designTypeOptions = [
  { label: "6” x 9” Postcard", value: "postcard:6x9", icon: "mail", description: "110# paper, double sided" },
  { label: "5” x 7” Handwritten - Front", value: "design_template:5x7:handwritten", icon: "handwritten", description: "120# cover vellum flats" },
  { label: "5” x 7” Handwritten Card", value: "handwritten-card:5x7", icon: "handwritten", description: "120# cover vellum flats" },
  { label: "6.5” x 9” EDDM Postcard", value: "eddm:6.5x9", icon: "eddm", description: "110# paper, double sided" },
  { label: "6.5” x 11” EDDM Postcard", value: "eddm:6.5x11", icon: "eddm", description: "110# paper, double sided" },
  { label: "8.5” x 11” EDDM Postcard", value: "eddm:8.5x11", icon: "eddm", description: "110# paper, double sided" },
  { label: "Goodies Box", value: "goodie:cookies", icon: "cookie", description: "Custom Branded Box, (4) Gourmet Cookies" },
  // { label: "12” x 18” Yard Sign", value: "yard-sign:6.5x11", icon: "images", description: "Chloroplast corrugated" },
];


const uploadOptions = [
  { label: "Yes I have designs ready to go", value: 'upload' },
  { label: "Nope, I want to create one in the Design Builder", value: 'builder' },
];

const designApi = new DopeApi("design");

const DesignsPage = () => {
  const query = useQuery2({ api: designApi, initialSearchScope: 'name' });

  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  const { actions, design, isSaving, isGetting } = useDesign(id);

  const [addDesignSelectOpen, setAddDesignSelectOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [editing, setEditing] = useState(false);

  const [uploadOption, setUploadOption] = useState('upload');

  useEffect(() => {
    if (location.state && location.state.createDesign) {
      setAddDesignSelectOpen(true);
    }
  }, []);

  useEffect(() => {
    if (!id) {
      actions.resetToInitial();
    }
  }, [id]);

  const actionButtons = (
    <>
      <DopeButton
        icon={<Icon as={FaPlus} size={"10.5px"} style={{margin: "2px 4px 0 0"}} />}
        props={{
          onClick: () => window.open('https://forms.monday.com/forms/28ebd4dbffa54716a1bb26abc6bbd073?r=use1', '_blank'),
          label: "Request a Design",
          buttonClass: "outlined-red",
          styles: { marginRight: "10px" },
        }}
      />

      <DopeButton
        icon={<Icon as={FaPlus} size={"10.5px"} style={{margin: "2px 6px 0 0"}} />}
        props={{
          onClick: () => setAddDesignSelectOpen(true),
          label: "Add New Design",
          buttonClass: "filled",
        }}
      />
    </>
  );

  const handleCreateNew = (designTypeSelection) => {
    const [type, size, templateType] = designTypeSelection.split(':');

    setAddDesignSelectOpen(false);
    if (type === 'handwritten-card') {
      navigate(`/designs/${type}s/new/setup?type=${type}&size=${size}`);
    } else if (type === 'goodie') {
      navigate(`/designs/${type}s/new?type=${type}&goodie=${size}`);
    } else if (type === 'design_template') {
      if (templateType === 'handwritten') {
        navigate(`/designs/handwritten-fronts/new?type=${type}&size=${size}`);
      }
    } else {
      navigate(`/designs/${type}s/new?type=${type}&size=${size}`);
    }
  };

  const onClose = () => {
    navigate('/designs');
  };

  return (
    <>

      <DopeContentPage
        pageTitle="My Designs"
        actionButtons={actionButtons}
        pageContent={<DesignGridPage useQuery={query}/>}
      />

      <DopeSelectDrawer
        header={"What type of design do you want to add?"}
        open={addDesignSelectOpen}
        onCancel={() => setAddDesignSelectOpen(false)}
        onAction={(selectedOption) => {
          if (selectedOption.includes('eddm') || selectedOption.includes('postcard')) {
            setSelected(designTypeOptions.find(option => option.value === selectedOption));
            setAddDesignSelectOpen(false);
          } else {
            handleCreateNew(selectedOption);
          }
        }}
        options={designTypeOptions}
      />

      <DopeDrawer
        open={selected !== null}
        onClose={() => {
          setSelected(null);
          setAddDesignSelectOpen(true);
        }}
        bodyContent={
          <div className="upload-drawer">
            {selected && (
              <DopeIconCard
                icon={selected.icon}
                header={selected.label}
                subheader={selected.description}
                selected={true}
                disabled={selected.disabled}
                onClick={() => {}}
              />
            )}

            <h2>Do you have a design you want to upload?</h2>
            <DopeRadio
              name='upload'
              value={uploadOption}
              options={uploadOptions}
              onChange={(s) => setUploadOption(s.upload)}
            />

            <Divider style={{ margin: 0 }} />

            <div className="space-between pad">
              <DopeButton
                props={{
                  label: "Cancel",
                  onClick: () => {
                    setSelected(null);
                    setAddDesignSelectOpen(true);
                  },
                  buttonClass: "outlined-red create-cancel",
                }}
              />

              <DopeButton
                icon={<Icon as={FaPlus} size={"10.5px"} style={{margin: "2px 4px 0 0"}} />}
                props={{
                  label: "Create",
                  onClick: () => {
                    if (uploadOption === 'upload') {
                      handleCreateNew(selected.value);
                    } else {
                      const [type, size] = selected.value.split(':');
                      navigate(`/editor/new?type=${type}&size=${size}`);
                    }
                  },
                  buttonClass: "filled create-cancel",
                }}
              />
            </div>
          </div>
        }
      />

      <DopeDrawer
        open={!!id}
        onClose={onClose}
        bodyContent={
          <DesignOverviewDrawer
            design={design}
            actions={actions}
            reload={query.reload}
            closeDrawer={onClose}
            setEditing={setEditing}
            loading={isGetting}
            isSaving={isSaving}
          />
        }
      />

      <DopeDrawer
        open={!!id && editing}
        onClose={() => setEditing(false)}
        bodyContent={
          <DesignEditDrawer
            design={design}
            actions={actions}
            reload={query.reload}
            setEditing={setEditing}
            loading={isSaving}
          />
        }
      />
    </>
  );
};

export default DesignsPage;
