import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Accordion } from 'rsuite';
import { Icon } from "@rsuite/icons";
import { MdOutlineChevronRight } from "react-icons/md";

import HandwrittenCardBodyPreview from "./HandwrittenCardBodyPreview";

import DopeButton from "../ui/DopeButton";
import DopeConfirmationModal from "../ui/DopeConfirmationModal";

import {formatToLocaleDateString, timestampToDateTimeStr} from "../utils/date";
import { BsPaperclip } from "react-icons/bs";
import { humanize } from "../utils/railsNames";
import {DopeFormGroup} from "../ui/DopeForm";
import MailTemplateDetails from "./MailTemplateDetails";
import DopeLoader from "../ui/DopeLoader";

import './DesignOverview.scss';

const PostcardOverview = ({ design }) => {

  const [view, setView] = useState('front');

  return (
    <div className="postcard-overview">
      <div className="flex row margin-4-b">
        <div className="image-preview-container" onClick={() => setView('front')}>
          <img
            src={design.mail_template.front_image_thumbnail_url}
            className={view === 'front' ? 'image-preview selected' : 'image-preview'}
            alt="Postcard Front"
          />
          <div className="image-preview-overlay-text">Front</div>
        </div>

        <div className="image-preview-container" onClick={() => setView('back')}>
          <img
            src={design.mail_template.back_image_thumbnail_url}
            className={view === 'back' ? 'image-preview selected' : 'image-preview'}
            alt="Postcard Back"
          />
          <div className="image-preview-overlay-text">Back</div>
        </div>
      </div>
      {view === 'front' &&
        <img src={design.mail_template.front_image_url} className="design-image" alt="Postcard Preview"/>}
      {view === 'back' &&
        <img src={design.mail_template.back_image_url} className="design-image" alt="Postcard Preview"/>}

    </div>
  );
};

const TemplateOverview = ({ design }) => {

  const [view, setView] = useState('front');

  return (
    <div className="postcard-overview">
      <div className="flex row margin-4-b">
        <div className="image-preview-container" onClick={() => setView('front')}>
          <img
            src={design.mail_template.front_image_thumbnail_url}
            className={view === 'front' ? 'image-preview selected' : 'image-preview'}
            alt="Postcard Front"
          />
          <div className="image-preview-overlay-text">Front</div>
        </div>

        {design.mail_template.back_image_thumbnail_url && (
          <div className="image-preview-container" onClick={() => setView('back')}>
            <img
              src={design.mail_template.back_image_thumbnail_url}
              className={view === 'back' ? 'image-preview selected' : 'image-preview'}
              alt="Postcard Back"
            />
            <div className="image-preview-overlay-text">Back</div>
          </div>
        )}
      </div>
      {view === 'front' &&
        <img src={design.mail_template.front_image_url} className="design-image" alt="Postcard Preview"/>}
      {view === 'back' &&
        <img src={design.mail_template.back_image_url} className="design-image" alt="Postcard Preview"/>}

    </div>
  );
};

const HandwrittenCardOverview = ({design}) => {

  const [view, setView] = useState('cover');

  return (
    <div className="postcard-overview">
      <div className="flex row margin-4-b">
        <div className="image-preview-container" onClick={() => setView('cover')}>
          <img
            src={design.mail_template.cover_image_url}
            className={view === 'cover' ? 'image-preview selected' : 'image-preview'}
            alt="Card Cover"
          />
          <div className="image-preview-overlay-text">Cover</div>
        </div>


        <div className="image-preview-container" onClick={() => setView('body')} style={{ color: "black"}}>
          <div className={view === 'body' ? 'image-preview selected' : 'image-preview'} style={{overflow: "hidden"}}>
            <HandwrittenCardBodyPreview
              font={design.mail_template.font}
              body={design.mail_template.body}
              signature={design.mail_template.signature}
              limit={design.mail_template?.card?.characters || 500}
              />
          </div>
          <div className="image-preview-overlay-text">Body</div>
        </div>

      </div>
      {view === 'cover' &&
        <img src={design.mail_template.cover_image_url} className="design-image" alt="Card Cover"/>}

      {view === 'body' &&
        <div className="design-image" style={{ overflow: "hidden"}}>
          <HandwrittenCardBodyPreview
            header={design.mail_template.header_image_url}
            footer={design.mail_template.footer_image_url}
            font={design.mail_template.font}
            body={design.mail_template.body}
            signature={design.mail_template.signature}
            limit={design.mail_template?.card?.characters || 500}
          />
        </div>}
    </div>
  );
};

const GoodieTemplateOverview = ({design}) => {

  const [view, setView] = useState('custom');
  return (
    <div className="handwritten-card-overview">
      <div className="mini-preview margin-4-b">
        <img
          src={design.mail_template.custom_image_url}
          className={view === 'custom' ? 'image-preview selected' : 'image-preview'}
          onClick={() => setView('custom')}
          alt="Custom Logo"
        />
      </div>

      {view === 'custom' && <img src={design.mail_template.custom_image_url} className="design-image margin-4-b" alt="Custom Logo"/>}
    </div>
  );
};

const DesignHistory = ({ activity, versions, design }) => {


  const date = timestampToDateTimeStr(activity.created_at);
  const creator = activity.creator.full_name;

  let message = '';
  let attachments = [];

  if (activity.action === 'created') {
    message = `created Version #1`;
    attachments.push({ name: 'Front', url: versions[0].mail_template.front_image_url });
    attachments.push({ name: 'Back', url: versions[0].mail_template.back_image_url });

  } else if (activity.action === 'updated') {

    const { mail_template_id, status } = activity.data;

    // When the mail template changes a new version is created
    if (Array.isArray(mail_template_id)) {

      const versionNumber = versions.findIndex(v => v.mail_template.id === mail_template_id[1]) + 1;
      message = `created Version #${versionNumber}`;

      const version = versions[versionNumber - 1];

      attachments.push({ name: 'Front', url: version.mail_template.front_image_url });
      attachments.push({ name: 'Back', url: version.mail_template.back_image_url });

    } else if (Array.isArray(status)) {
      const newStatus = status[1];

      if (newStatus === 'in_design') {
        message = `submitted revision notes`;

        const version = versions[versions.length - 1];

        version.attachments_urls.forEach(attachment => {
          attachments.push({ name: attachment.filename, url: attachment.url });
        });
      } else if (newStatus === 'approved') {
        message = `approved design at ${timestampToDateTimeStr( design.approved_at)}`;
      }
    }
  }

  return (
    <div className="design-activity-history-item">
      <p>{creator} {message}</p>
      {attachments.length > 0 && (
        <p className="design-activity-history-attachments">
          {attachments.map((attachment, index) => (
            <span>
              <BsPaperclip />
              <a key={index} href={attachment.url} target="_blank" rel="noreferrer">{attachment.name}</a>
            </span>
          ))}
        </p>
      )}
      <p><small>{date}</small></p>
    </div>
  );

};

const mailerTypes = {
  GoodieTemplate: "Goodie",
  HandwrittenCardTemplate: "Handwritten Card",
}


const DesignOverviewDrawer = ({ design, actions, reload, setEditing, closeDrawer, loading, isSaving }) => {
  const navigate = useNavigate();

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRestoreModal, setShowRestoreModal] = useState(false);

  if (loading || !design) { // TODO I added loading until we have a design but did not update all the conditional checks bc no time...
    return <DopeLoader />;
  }

  let productLabel = design ? design.mail_template.product_label : '';

  if (design?.mail_template_type === 'DesignTemplate') {
    if (design?.mail_template.template_type === 'HandwrittenCardTemplate') {
      productLabel = 'HWC - Front';
    }
  }

  return (
    <div className="design-overview">
      <div className="design-overview-template">
        <h3 className="header-3 margin-4-b">"{design?.name}"</h3>
        <div className="badge text-upper margin-20-b">
          {productLabel}
        </div>

        {design?.mail_template_type === "PostcardTemplate" && <PostcardOverview design={design}/>}
        {design?.mail_template_type === "HandwrittenCardTemplate" && <HandwrittenCardOverview design={design}/>}
        {design?.mail_template_type === "GoodieTemplate" && <GoodieTemplateOverview design={design}/>}
        {design?.mail_template_type === "DesignTemplate" && <TemplateOverview design={design}/>}

        <div className="design-details-container">
          <div className="space-between row margin-4-b">
            <div className="bold">Mailer Type</div>
            <div>{humanize(design?.mail_template.mailer_type ? design?.mail_template.mailer_type : mailerTypes[design?.mail_template_type])}</div>
          </div>

          <div className="space-between row margin-4-b">
            <div className="bold">Mailer Tag</div>
            <div>{humanize(design?.mail_template.objective_type || "")}</div>
          </div>

          <div className="space-between row margin-4-b">
            <div className="bold">Created Date</div>
            <div>{formatToLocaleDateString(design?.created_at)}</div>
          </div>

          <div className="space-between row margin-4-b">
            <div className="bold">Status</div>
            <div>{humanize(design?.status || "")}</div>
          </div>
        </div>

        <div className="to-right margin-16-b">
          <DopeButton
            props={{
              buttonClass: "text-link",
              onClick: () => setEditing(true)
            }}>Edit Details <Icon as={MdOutlineChevronRight} size="18px" style={{margin: "2px 0 0 4px"}}/></DopeButton>
        </div>

        <hr className="margin-none"/>

        <div className="margin-top">
          <h5 className="header-5 margin-4-b">Tags</h5>
          {design && <DopeFormGroup
            input={{
              inputType: "tags",
              name: "taggings",
              props: {modelName: "design"}
            }}
            values={design}
            onChange={actions.addTagsToDesign}
          />}

        </div>

        <hr className="margin-16-t margin-16-b"/>

        {design && <MailTemplateDetails design={design} templateType={design.mail_template_type} headerText="Design Details" />}

        <div className="margin-16-tb">
          <Accordion>
            <Accordion.Panel header="Design History">
              <div className="design-activity-history">
                {design?.activity_histories.map(activity => <DesignHistory
                  key={activity.id}
                  activity={activity}
                  versions={design.design_versions}
                  design={design}
                />)}
              </div>
            </Accordion.Panel>
          </Accordion>
        </div>

        <div className="design-overview-actions">
          {design?.status === 'approved' && (
            <DopeButton
              props={{
                onClick: () => setShowArchiveModal(true),
                buttonClass: "outlined-black",
              }}
            >
              Archive Design
            </DopeButton>
          )}

          {design?.status === 'draft' && design.mail_template_type === 'HandwrittenCardTemplate' && (
            <DopeButton
              props={{
                onClick: () => {
                  if (design.mail_template_type === 'HandwrittenCardTemplate') {
                    navigate(`/designs/handwritten-cards/${design.id}/setup?type=handwritten-card&size=5x7`);
                  }
                },
                buttonClass: "outlined-black",
              }}
            >
              Edit Design
            </DopeButton>
          )}

          {design?.status === 'archived' && design?.approved_at && (
            <DopeButton
              props={{
                onClick: () => setShowApproveModal(true),
                buttonClass: "outlined-black",
              }}
            >
              Move to Approved
            </DopeButton>
          )}

          {design?.status === 'archived' && !design?.approved_at && (
            <DopeButton
              props={{
                onClick: () => setShowRestoreModal(true),
                buttonClass: "outlined-black",
              }}
            >
              Restore Design
            </DopeButton>
          )}

          {(design?.status === 'needs_review' || design?.status === 'in_design') && (
            <DopeButton
              props={{
                onClick: () => navigate(`/designs/${design.id}/revision`),
                buttonClass: "filled-black",
              }}
            >
              View Revision
            </DopeButton>
          )}

          {design?.mail_template_format === 'json' && design?.status !== 'in_design' && design?.status !== 'archived' && (
            <DopeButton
              props={{
                onClick: () => {
                  if (design.approved_at && design.status !== 'draft') {
                    actions.resetToInitial();
                    navigate(`/editor/new?type=postcard&size=${design.mail_template.size}&clone=${design.id}`);
                    return;
                  }
                  navigate(`/editor/${design.id}?type=postcard&size=${design.mail_template.size}`);
                },
                buttonClass: "filled-black",
              }}
            >
              Open In Design Tool
            </DopeButton>
          )}
        </div>
      </div>

      <DopeConfirmationModal
        open={design?.id && showArchiveModal}
        title={`Archive design “${design?.name}”`}
        message='Are you sure you want to archive your design? Your design will not be deleted but will be in your “Archived” folder and can always be moved back to your “Approved” designs in the future.'
        onConfirm={async () => {
          try {
            await actions.archive();
            setShowArchiveModal(false);
            reload();
            closeDrawer();
          } catch (e) {
            console.error(e);
          }
        }}
        onCancel={() => setShowArchiveModal(false)}
        errors={design?.errors || []}
        loading={isSaving}
        confirmLabel='Yes, Archive Design'
        cancelLabel='Keep Design as Approved'
      />

      <DopeConfirmationModal
        open={design?.id && showApproveModal}
        title={`Move design “${design?.name}” back to APPROVED`}
        message='Are you sure you want to move your design back to Approved?'
        onConfirm={async () => {
          try {
            await actions.approve();
            setShowApproveModal(false);
            reload();
            closeDrawer();
          } catch (e) {
            console.error(e);
          }
        }}
        onCancel={() => setShowApproveModal(false)}
        errors={design?.errors || []}
        loading={isSaving}
        confirmLabel='Yes, Move to Approved'
        cancelLabel='Keep Design as Archived'
      />

      <DopeConfirmationModal
        open={design?.id && showRestoreModal}
        title={`Restore design “${design?.name}” back to NEEDS REVIEW`}
        message='Are you sure you want to restore your design back to Needs Review?'
        onConfirm={async () => {
          try {
            await actions.restoreForRevision();
            setShowRestoreModal(false);
            reload();
            closeDrawer();
          } catch (e) {
            console.error(e);
          }
        }}
        onCancel={() => setShowRestoreModal(false)}
        errors={design?.errors || []}
        loading={isSaving}
        confirmLabel='Yes, Restore Design'
        cancelLabel='Keep Design as Archived'
      />

    </div>
  );
};

export default DesignOverviewDrawer;
