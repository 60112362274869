import React from 'react';
import './DopeDropdown.scss';

import ContactDropdown from "../contacts/ContactDropdown";
import ContactListDropdown from "../contacts/ContactListDropdown";
import ListDropdown from "../lists/ListDropdown";
import QRCodeDropdown from "../analysis/QRCodeDropdown";
import TrackingNumberDropdown from '../analysis/TrackingNumberDropdown';
import EddmOrderDropdown from '../eddm/EddmOrderDropdown';
import SweetSpotAnalysisDropdown from '../analysis/SweetSpotAnalysisDropdown';
import HotLeadDropdown from '../analysis/HotLeadDropdown';


const getCustomStyles = (dropDownType) => {
  switch (dropDownType) {
    case 'tracking_number':
    case 'qr_code':
    case 'hot_lead':
    case 'sweet_spot_analysis':
      return {
        padding: '20px',
      };
    default:
      return {};
  }
}

const DropdownContent = ({ dropDownType, onClose, rowData, list, reloadTable }) => {
  switch (dropDownType) {
    case 'contact':
      return <ContactDropdown rowData={rowData} onClose={onClose} />;
    case 'list':
      return <ListDropdown list={list} />;
    case 'contact_list':
      return <ContactListDropdown rowData={rowData} onClose={onClose} reloadTable={reloadTable} />;
    case 'qr_code':
      return <QRCodeDropdown rowData={rowData} onClose={onClose} />;
    case 'tracking_number':
      return <TrackingNumberDropdown rowData={rowData} onClose={onClose} />
    case 'eddm_order':
      return <EddmOrderDropdown rowData={rowData} onClose={onClose} reloadTable={reloadTable} />;
    case 'hot_lead':
      return <HotLeadDropdown rowData={rowData} onClose={onClose} />;
    case 'sweet_spot_analysis':
      return <SweetSpotAnalysisDropdown rowData={rowData} onClose={onClose} />;
    default:
      return null;
  }
};

const DopeDropdown = React.forwardRef(({ dropDownType, rowData, toggleWhisper, style, onClose, reloadTable, ...rest }, ref) => {
  const customStyle = getCustomStyles(dropDownType);
  const finalStyles = {...style, ...customStyle };

  return (
    <div {...rest} style={finalStyles} className={`dope-dropdown ${dropDownType}`} ref={ref}>
      <DropdownContent
        rowData={rowData}
        onClose={onClose}
        reloadTable={reloadTable}
        dropDownType={dropDownType}
      />
    </div>
  );
});

export default DopeDropdown;
