import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import WarningRoundIcon from '@rsuite/icons/WarningRound';
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';
import { Icon } from '@rsuite/icons';
import { MdMailOutline, MdCheckCircle } from "react-icons/md";
import { PiClockClockwiseBold } from "react-icons/pi";
import { IoPieChart } from "react-icons/io5";

import { deserializeParams } from "../services/dopeClient";
import DopeApi from "../services/DopeApi";
import { useQuery2 } from "../hooks/useQuery";
import { useDopeUI } from "../ui/dopeUISlice";
import DopeListPage from "../ui/DopeListPage";
import {handleDateFilter, handleFilterChange} from "../utils/fn";
import DopeDrawer from "../ui/DopeDrawer";
import { useMailPieceUI } from "./mailPieceUISlice";
import MailPieceDrawer from "./MailPieceDrawer";
import DopeConfirmationModal from "../ui/DopeConfirmationModal";

const mailPieceApi = new DopeApi("mail_piece");
const campaignApi = new DopeApi("campaign");

const disableCheck = (rowData) => {
  return rowData.status !== "scheduled";
};

const columns = [
  { type: 'checkbox', dataKey: 'id', disableCheck: disableCheck },
  { type: "text", dataKey: "id", label: "ID", width: 85 },
  { type: "stacked_text", dataKey: ["contact.full_name", "contact.company_name"], label: "Recipient", width: 175, sortable: true },
  { type: "address_text", dataKey: "contact.full_address", label: "Address", width: 175 },
  { type: "name_link", dataKey: "campaign_name", label: "Campaign Name", getUrl: (mail_piece) => `/campaigns/${mail_piece.campaign_id}/show`, flexGrow: 3 },
  { type: "text", dataKey: "product_name", label: "Mailer Type", width: 150, humanize: true },
  { type: "text", dataKey: "dispatch.date", label: "Send Date", sortable: true, width: 110 }, // date formatter is thinking dates in zero offset timezone
  { type: "pill", pillTypeKey: "status", dataKey: "effective_status", label: "Status", sortable: true, width: 175 },
  { type: "icon", dataKey: "id", icon: <ArrowRightLineIcon />, width: 50 },
];

const statusOptions = [
  { value: "cancelled", label: "Cancelled" },
  { value: "scheduled", label: "Scheduled" },
  { value: "processing", label: "Processing" },
  { value: "delivered", label: "Sent" },
  { value: "undeliverable", label: "Undeliverable" },
  { value: "duplicate", label: "Duplicate"},
  { value: "error", label: "Error" },
];

const mailerOptions = [
  { value: "PostcardTemplate", label: "Postcard" },
  { value: "GoodieTemplate", label: "Goodies" },
  { value: "HandwrittenCardTemplate", label: "Handwritten" },
];

function capitalizeFirstLetter(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const statusDivStyles = {
  cursor: "pointer",
  padding: "10px 20px",
  borderRadius: "8px",
  width: "234px",
  marginRight: "10px"
};

const mailStatusConfig = [
  { label: "All", logoColor: "#494B4E", dataKey: "all", icon: MdMailOutline },
  { label: "Scheduled", logoColor: "#8793A6", dataKey: "scheduled", icon: PiClockClockwiseBold },
  { label: "Sent", logoColor: "#255FDF", dataKey: "shipped", icon: MdCheckCircle },
  // { label: "En Route", logoColor: "#255FDF", dataKey: "sent", icon: IoPieChart },
  // { label: "Delivered", logoColor: "#4CAF50", dataKey: "delivered", icon: MdCheckCircle },
  // { label: "Not Delivered", logoColor: "#F44336", dataKey: "undeliverable", icon: <RemindFillIcon /> },
];

const mailPiecesByStatus = (status, statusCounts, selectedStatus, onStatusClick) => {
  const statusData = statusCounts[status.dataKey];
  const isSelected = selectedStatus === status.dataKey;

  const divStyles = isSelected ? { ...statusDivStyles, border: "2px solid #255FDF", background: "#BCD2E11A"} : { ...statusDivStyles, border: "1px solid #EAECEF", };

  return (
    <div onClick={() => onStatusClick(status.dataKey, isSelected)} style={divStyles} key={status.dataKey}>
      <div className="vertical-align row" style={{marginBottom: "12px"}}>
        <Icon as={status.icon} style={{color: status.logoColor, marginRight: "4px"}} size="16px" />
        <h6>{status.label}</h6>
      </div>
      {Object.keys(statusData).map(template_name => (
          <div key={`${template_name}-${status.dataKey}`} className="space-between row margin-4-b">
            <div className="label large">{capitalizeFirstLetter(template_name)}</div>
            <div className="header-6">{statusData[template_name]}</div>
          </div>
        )
      )}
    </div>
  )
};

const MailPieceHistory = () => {
  const [statusCounts, setStatusCounts] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [selectedMailPieces, setSelectedMailPieces] = useState([]);
  const [confirming, setConfirming] = useState(false);
  const [dopeUI, dopeUIActions] = useDopeUI();
  const [mailPieceUI, mailPieceUIActions] = useMailPieceUI();
  const location = useLocation();
  const searchParams = deserializeParams(location.search);
  const initialScopes = searchParams.initialScopes || [];

  const { error, tableProps, paginationProps , onSearchClear, onSearchInput, setFilters, filters, setScopes, scopes, reload }
    = useQuery2({
      api: mailPieceApi,
      initialScopes: initialScopes,
      initialSearchScope: "contacts.full_name_and_address",
    });

  const getStatusCounts = () => {
    mailPieceApi.fetchCollection("template_counts_by_status").then(setStatusCounts)
  };

  useEffect(getStatusCounts, []);

  const applyCampaignIdFilter = (campaigns) => {
    const filterObject = {
      filterField: "dispatches.campaign_id",
      operator: "in",
      filterCategory: "Campaign",
    }
    handleFilterChange(filters, setFilters, campaigns, filterObject)
  }

  const applyStatusFilter = (status) => {
    const filterObject = {
      filterField: "mail_pieces.status",
      operator: "in",
      filterCategory: "Status",
    }
    handleFilterChange(filters, setFilters, status, filterObject)
  }

  const applyMailerTypeFilter = (mailerTypes) => {
    const filterObject = {
      filterField: "dispatches.mail_template_type",
      operator: "in",
      filterCategory: "Type",
    }
    handleFilterChange(filters, setFilters, mailerTypes, filterObject)
  }

  const applyDateRangeFilter = (startDate, endDate) => {
    handleDateFilter(filters, setFilters, "dispatches.date", startDate, endDate);
  }

  // TODO: Make real, options are being limited by pagination, need to find an efficient way to get all options
  const filterInputs = [
    { title: "Campaign",
      getOptions: (name = "") => campaignApi.getOptions({
        valueKey: "id",
        labelKey: "name",
        search: name.length > 0 ? { text: name, column: "name" } : {},
      }),
      applyFilter: applyCampaignIdFilter,
      searchable: true
    },
    { title: "Status", getOptions: () => { return statusOptions }, applyFilter: applyStatusFilter },
    { title: "Type", getOptions: () => { return mailerOptions }, applyFilter: applyMailerTypeFilter },
    { title: "Date Range", type: "dateRange" , applyFilter: applyDateRangeFilter },
  ]

  const onStatusClick = (status, isSelected) => {
    if (status === "all") {
      setScopes(initialScopes);
      setSelectedStatus("all");
      return;
    }

    if (!isSelected) {
      const newScopes = [...initialScopes, { name: status }]
      setScopes(newScopes);
      setSelectedStatus(status);
    }
  }

  const cancelMail = async (mailPieceIds) => {
    if (!tableProps.data.filter(mailPiece => mailPieceIds.includes(mailPiece.id)).some(mailPiece => mailPiece.status === "scheduled")) {
      dopeUIActions.addFlashMessage({header: "Mail not cancellable", body: "Only mail pieces that have not been sent can be cancelled", type: "warning"});
      return;
    }

    await mailPieceApi.post({ mail_piece_ids: mailPieceIds }, "cancel_mail_pieces");
    reload();
    getStatusCounts();
    dopeUIActions.addFlashMessage({ header: "Mail Cancelled", body: "Your mail has been cancelled", type: "success" });
  };

  const cancelMailClick = (mailPieceIds) => {
    setSelectedMailPieces(mailPieceIds);
    setConfirming(true);
  }

  const selectedActionObjects = [
    { label: "Cancel Mail", action: cancelMailClick, icon: <WarningRoundIcon /> }
  ];

  let selectedMailPieceText;
  if (selectedMailPieces.length === 1) {
    selectedMailPieceText = selectedMailPieces[0]
  } else if (selectedMailPieces.length > 1) {
    selectedMailPieceText = `${selectedMailPieces.slice(0, selectedMailPieces.length - 1).join(", ")} and ${selectedMailPieces[selectedMailPieces.length - 1]}`;
  }

  return (
    <>
      <div style={{display: "flex", flexDirection: "row", margin: "30px", justifyContent: "center"}}>
        {statusCounts && mailStatusConfig.map((status) => (
          mailPiecesByStatus(status, statusCounts, selectedStatus, onStatusClick)
        ))}
      </div>


      <h5 style={{ fontWeight: 600, fontSize: "18px", color: "#000000"}}>Mail Pieces</h5>
      <DopeListPage
        tableProps={tableProps}
        paginationProps={paginationProps}
        columns={columns}
        selectedActions={selectedActionObjects}
        onSearchClear={onSearchClear}
        onSearchChange={onSearchInput}
        setFilters={setFilters}
        filters={filters}
        filterInputs={filterInputs}
        error={error}
        onClickId={(mailPiece) => mailPieceUIActions.setDrawerMailPieceId(mailPiece.id)}
      />

      <DopeDrawer
        open={!!mailPieceUI.drawerMailPieceId}
        onClose={() => mailPieceUIActions.setDrawerMailPieceId(null)}
        bodyContent={<MailPieceDrawer id={mailPieceUI.drawerMailPieceId} />}
      />

      <DopeConfirmationModal
        open={confirming}
        title="Cancel Mail"
        message={(
          <div>
            Are you sure you want to cancel mail piece(s) {selectedMailPieceText}?
          </div>
        )}
        onConfirm={async () => {
          await cancelMail(selectedMailPieces)
          setConfirming(false);
        }}
        onCancel={() => setConfirming(false)}
        confirmLabel="Yes, Cancel Mail"
        cancelLabel="No, Keep Mail Scheduled"
      />
    </>
  );
}

export default MailPieceHistory;
