import React, { useEffect, useRef } from "react";

import { useQuery2 } from "../hooks/useQuery";
import DopeApi from "../services/DopeApi";

import DopeListPage from "../ui/DopeListPage";
import DopeDrawer from "../ui/DopeDrawer";
import { formatToLocaleDateString } from "../utils/date";
import TrackingNumberDrawer from "./TrackingNumberDrawer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTrackingNumberUI } from "./trackingNumberUISlice";
import TrackingNumberEventsDrawer from "./TrackingNumberEventsDrawer";
import { useTrackingNumber } from "./trackingNumberSlice";
import { formatPhone } from "../utils/phone";
import DopeConfirmationModal from "../ui/DopeConfirmationModal";
import DopePill from "../ui/DopePill";

const api = new DopeApi('tracking_numbers');
const campaignApi = new DopeApi("campaign");

const columns = [
  { type: "custom", dataKey: 'number', label: "Tracking Number", flexGrow: 2, sortable: true, render: (rowData) => {
    return (
      <div >
        <p style={{margin: 0}}>{formatPhone(rowData.number)}</p>
        <p style={{margin: 0}}><small>{rowData.name}</small></p>
      </div>
    );
  }},
  { type: "custom", dataKey: "forward_to", label: "Forwards to", flexGrow: 2, render: (rowData) => {
    if (rowData.status === "active") {
      return formatPhone(rowData.forward_to);
    }

    if (rowData.status === "canceled") {
      return <DopePill text={"Canceled"} />;
    }

    return null;
  }},
  { type: "event_time", dataKey: "last_called_at", label: "Last Call", sortable: true, flexGrow: 2 },
  { type: 'date', dataKey: 'created_at', label: 'Created Date', sortable: true, filterable: false, dateConverter: formatToLocaleDateString },
  { type: 'dropdown', dataKey: 'actions', dropDownType: 'tracking_number', flexGrow: 1 }
];

const TrackingNumbersTable = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { trackingNumber, actions: trackingNumberActions, isSaving } = useTrackingNumber();
  const [ trackingNumberUI, actions ] = useTrackingNumberUI();

  const prevSavingState = useRef(false);

  useEffect(() => {
    if (id && !location.pathname.includes("calls")) {
      actions.setDrawerTrackingNumberId(id);
    } else if (id && location.pathname.includes("calls")) {
      trackingNumberActions.get(id);
    }
  }, [id]);

  useEffect(() => {
    const prevSaving = prevSavingState.current;

    if (prevSaving && !isSaving && trackingNumberUI.cancelTrackingNumberId) {
      actions.setCancelTrackingNumberId(null);
      reload();
    }

    prevSavingState.current = isSaving;
  }, [isSaving]);

  useEffect(() => {
    if (trackingNumberUI.cancelTrackingNumberId) {
      trackingNumberActions.get(trackingNumberUI.cancelTrackingNumberId);
    }
  }, [trackingNumberUI.cancelTrackingNumberId]);

  const { error, tableProps, paginationProps, onSearchInput, onSearchClear, setFilters, filters, setScopes, scopes, reload }
  = useQuery2({
    api,
    initialFilters: [],
    initialSearchScope: "name_and_number",
  });


  const closeDrawer = (shouldReload = false) => {
    actions.setDrawerTrackingNumberId(null);
    if (shouldReload) {
      reload();
    }
    navigate("/tracking_numbers");
  };


  return (
    <>
      <DopeListPage
        columns={columns}
        tableProps={tableProps}
        paginationProps={paginationProps}
        error={error}
        filterInputs={[]}
        onSearchChange={onSearchInput}
        onSearchClear={onSearchClear}
        setFilters={setFilters}
        //onClickId={(mailPiece) => actions.setDrawerMailPieceId(mailPiece.id)}
      />

      <DopeDrawer
         open={!!trackingNumberUI.drawerTrackingNumberId}
         onClose={closeDrawer}
         bodyContent={<TrackingNumberDrawer
          id={trackingNumberUI.drawerTrackingNumberId}
          onClose={closeDrawer}
          reload={reload}
        />}
        header="Tracking Number Setup"
        subheader={"Tracking numbers are $5/month each."}
      />

      <TrackingNumberEventsDrawer
        open={!!id && location.pathname.includes("calls")}
        onClose={() => {
          trackingNumberActions.resetToInitial();
          navigate("/tracking_numbers");
        }}
        trackingNumber={trackingNumber}
      />

      <DopeConfirmationModal
        open={!!trackingNumberUI.cancelTrackingNumberId && trackingNumber}
        title={`Cancel Tracking Number ${formatPhone(trackingNumber?.number)}?`}
        message="Are you sure you want to cancel this tracking number? If you do, any future calls to this number will either go dead or get forwarded to someone else who picked up this number"
        onConfirm={async () => {
          trackingNumberActions.cancel();
        }}
        onCancel={() => actions.setCancelTrackingNumberId(null)}
        confirmLabel='Yes, Cancel'
        cancelLabel='No, Keep'
        confirmButtonClass='filled-black'
        cancelButtonClass='outlined'
        loading={isSaving}
      />
    </>
  );
};

export default TrackingNumbersTable;
